<template>
	<div class="container">
		<img class="imgbox" src="//cdn.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B03_1.jpg">
		<img class="imgbox" src="//cdn.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B03_2.jpg">
		<img class="imgbox" src="//cdn.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B03_3.jpg">
		<img class="imgbox" src="//cdn.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B03_4.jpg">
		<img class="imgbox" src="//cdn.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B03_5.jpg">
		<img class="imgbox" src="//cdn.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B03_6.jpg">
		<img class="imgbox" src="//cdn.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B03_7.jpg">
		<img class="imgbox" src="//cdn.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B03_8.jpg">
		<img class="imgbox" src="//cdn.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B03_9.jpg">
		<img class="imgbox" src="//cdn.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B03_10.jpg">
		<img class="imgbox" src="//cdn.zhiyileiju.cn/%E8%B5%B7%E5%90%8D%E6%A1%88%E4%BE%8B03_11.jpg">
		<van-button round color="#C51C22" @click="getpayurl" class="buyorderbtn111">点击请老师起名</van-button>
	</div>

</template>

<script>
	import axios from "../utils/request";
	import report from "../utils/stat.js"
	export default {
		async mounted() {
			if (this.is_weixn) {
				let wxconfig = await this.getWxConfig();
				wx.config({
					debug: false,
					appId: wxconfig.appId,
					timestamp: wxconfig.timestamp,
					nonceStr: wxconfig.nonceStr,
					signature: wxconfig.signature,
					jsApiList: ['chooseWXPay']
				});

			}
		},
		computed: {
			is_weixn() {
				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					return true;
				} else {
					return false;
				}
			},
		},
		methods: {
			async getWxConfig() {
				let url = window.location.href;

				let data = await axios.post("/payment/wechatconf", {
					url
				})
				return data.data.data.data;
			},
			async getpayurl() {
				let orderinfo
				report("v2_teacher_example_click"+this.$route.params.teacherid);
				try {
					orderinfo = await axios.post("/order/confirm", {
						"order_id": this.$route.params.id,
						"teacher_id": this.$route.params.teacherid,
						"from_order_id": this.$route.params.id,
						paytype: 2
					})
				} catch (err) {
					alert("下单失败")
				}
				try {
					if (this.is_weixn) {
						let payinfo = await axios.get(
							`https://api.zhiyileiju.cn/api/v1/payment/wechat/${orderinfo.data.data.id}/wechat`)
						let pay = payinfo.data.data;
						wx.ready(function() {
							wx.chooseWXPay({
								timestamp: pay.timeStamp,
								nonceStr: pay.nonceStr,
								package: pay.package,
								signType: pay.signType,
								paySign: pay.paySign, // 支付签名
								success: function(res) {
									router.push({
										name: 'teachersuccess'
									});
								}
							});
						});
					} else {
						location.href = `https://api.zhiyileiju.cn/api/v1/payment/wechat/${orderinfo.data.data.id}`;
					}
				} catch (err) {
					alert("下单失败")
				}
			}

		}
	}
</script>

<style>
	.iframe {
		width: 100vw;
		height: 100vh;
		overflow: scroll;
	}

	.buyorderbtn111 {
		display: block;
		margin: 0 auto;
		width: 80%;
		height: 40px;
		position: fixed;
		bottom: 40px;
		left: 10%;
		animation: scaleDrew 1.5s ease-in-out infinite;
	}

	.container {
		width: 100vw;
		margin: 0;
		padding: 0;
	}

	.imgbox {
		width: 100%;

	}
</style>
